<template>
  <Modal
    title="Rename Version"
    :form="{ data: form, method: 'PUT', endpoint: `collections/${sharedCollectionHashid}/personalisations/${personalisation.hashid}` }"
    confirm-label="Rename"
    @confirm="$modal.close"
  >
    <InputGroup label="Name" name="name" description="Example: August 23 Tour">
      <TextInput v-model="form.name" name="name" />
    </InputGroup>
  </Modal>
</template>

<script setup lang="ts">
const props = defineProps<{
  sharedCollectionHashid: string;
  personalisation: CollectionPersonalisation;
}>();

const form = reactive({
  name: props.personalisation.name,
});
</script>
